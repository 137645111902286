// TODO eslint rule 변경
import { css } from '@emotion/react';
import {
  faAngleDown,
  faArrowUpRightFromSquare,
  faBars,
  faXmark
} from '@fortawesome/pro-regular-svg-icons';
/* eslint-disable multiline-ternary */
import {
  ActionIcon,
  Box,
  Button,
  desktopFirstMediaQuery,
  Icon,
  Text,
  useTheme,
  type MantineTheme
} from '@inflearn/ds-react';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { useLocation } from '../../../../layouts/Infcon2023Layout';
import { URLS } from '../../../../utils/urls';
import useMainButtonText from '../hooks/useMainButtonText';

const MobileHeader = () => {
  const theme = useTheme();
  const location = useLocation();
  const { buttonText, buttonDisabled } = useMainButtonText();

  const getStyleWhenPathnameIncluded = (url: string | string[]) => {
    if (location == null) {
      return;
    }
    const style = styleActiveButton(theme);
    if (typeof url === 'object') {
      const filteredUrls = url.filter((urlItem) => location.pathname.includes(urlItem));
      if (isEmpty(filteredUrls)) {
        return false;
      }
      return style;
    }

    return location.pathname.includes(url) && style;
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickExpandButton = () => {
    setIsExpanded(!isExpanded);
  };

  const onClickOpenMenuButton = () => {
    setIsOpen(true);
  };

  const onClickCloseMenuButton = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Box component="header" css={styleMobileHeader(theme)} id={'infcon-2023-header-mobile'}>
        <Box
          css={{
            width: '100%',
            maxWidth: 1200
          }}>
          <Box css={styleMobileHeaderBody(theme)}>
            <a href={URLS.INFCON_2023__HOME}>
              <Text size="lg" weight="bold" color="black">
                INFCON 2023
              </Text>
            </a>
            {isOpen ? (
              <ActionIcon
                size={32}
                onClick={onClickCloseMenuButton}
                aria-label="모바일 GNB 목록 닫기">
                <Icon icon={faXmark} width={16} height={16} />
              </ActionIcon>
            ) : (
              <ActionIcon
                size={32}
                onClick={onClickOpenMenuButton}
                aria-label="모바일 GNB 목록 열기">
                <Icon icon={faBars} width={16} height={16} />
              </ActionIcon>
            )}
          </Box>
        </Box>
      </Box>
      {isOpen ? (
        <Box p={16} css={styleMobileHeaderMenu}>
          <Box component="ul">
            <Box component="li">
              <a
                href={URLS.INFCON_2023__SESSION}
                css={[mobileHeaderMeneItemStyle(theme), { marginTop: '10px' }]}>
                발표/프로그램
              </a>
            </Box>
            <Box
              component="li"
              css={[mobileHeaderMeneItemStyle(theme), { cursor: 'pointer' }]}
              role="button"
              aria-controls="header-dropdown-menu"
              onClick={onClickExpandButton}>
              행사 즐기기
              <Icon
                icon={faAngleDown}
                css={{
                  marginLeft: 6,
                  transform: isExpanded ? 'rotate(180deg)' : '',
                  transition: 'all 0.2s'
                }}
              />
            </Box>
            <Box
              component="ul"
              css={{ display: isExpanded ? 'block' : 'none' }}
              role="menu"
              aria-expanded={isExpanded}
              aria-orientation="vertical"
              aria-label="행사 즐기기 세부 목록"
              id="header-dropdown-menu">
              <Box component="li" role="menuitem" mt={6}>
                <a
                  href={URLS.INFCON_2023__EVENT}
                  css={[
                    mobileHeaderMenuSubItemStyle(theme),
                    getStyleWhenPathnameIncluded(URLS.INFCON_2023__EVENT)
                  ]}>
                  이벤트
                </a>
              </Box>
              <Box component="li" role="menuitem">
                <a
                  href={URLS.INFCON_2023__STORY}
                  css={[
                    mobileHeaderMenuSubItemStyle(theme),
                    getStyleWhenPathnameIncluded(URLS.INFCON_2023__STORY)
                  ]}>
                  스토리
                </a>
              </Box>
              <Box component="li" role="menuitem">
                <Box
                  component="a"
                  href={URLS.INFCON__ACTION}
                  css={[
                    mobileHeaderMenuSubItemStyle(theme),
                    getStyleWhenPathnameIncluded(URLS.INFCON__ACTION)
                  ]}>
                  이것만은 지켜요
                </Box>
              </Box>
            </Box>
            <Box component="li">
              <a
                href={URLS.INFCON_2023__FAQ}
                css={[
                  mobileHeaderMeneItemStyle(theme),
                  getStyleWhenPathnameIncluded(URLS.INFCON_2023__FAQ)
                ]}>
                FAQ
              </a>
            </Box>
            <Box component="li">
              <a
                href={URLS.INFCON_2023__MY}
                css={[
                  mobileHeaderMeneItemStyle(theme),
                  getStyleWhenPathnameIncluded(URLS.INFCON_2023__MY)
                ]}>
                MY
              </a>
            </Box>
            <Box component="li">
              <Button
                component="a"
                // TODO: 인프콘 2024때 부활
                // href={URLS.INFCON_2023_COURSE_PAGE}
                href={URLS.INFCON_2023_REPLAY}
                target="_blank"
                variant="filled"
                color="dark"
                size="md"
                radius="xl"
                fullWidth
                // leftIcon={<Icon icon={faBell} />}
                leftIcon={<Icon icon={faArrowUpRightFromSquare} />}
                mt={24}
                disabled={buttonDisabled}>
                {buttonText}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        ''
      )}
    </>
  );
};

const mobileHeaderMeneItemStyle = (theme: MantineTheme) =>
  css({
    display: 'block',
    padding: 2,
    marginTop: '24px',
    width: '100%',
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.colors.dark[9],
    ':hover': {
      color: theme.colors.blue[7]
    }
  });

const mobileHeaderMenuSubItemStyle = (theme: MantineTheme) =>
  css({
    display: 'block',
    width: '100%',
    padding: 12,
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.colors.gray[7],
    ':hover': {
      color: theme.colors.blue[7]
    }
  });

const styleMobileHeaderBody = (theme: MantineTheme) =>
  css({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '0 16px',
    maxWidth: 1200,
    color: theme.colors.gray[7]
  });

const styleMobileHeader = (theme: MantineTheme) =>
  css({
    position: 'absolute',
    width: '100%',
    height: '64px',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: 'inherit',
    backdropFilter: 'blur(20px)',
    [desktopFirstMediaQuery.mobile]: {
      display: 'flex'
    }
  });

const styleMobileHeaderMenu = css({
  position: 'absolute',
  display: 'none',
  width: '100%',
  backgroundColor: 'inherit',
  backdropFilter: 'blur(20px)',
  top: '64px',
  [desktopFirstMediaQuery.mobile]: { display: 'block' }
});

const styleActiveButton = (theme: MantineTheme) =>
  css({
    color: theme.colors.blue[7]
  });

export default MobileHeader;
